// Swiper
import {HashNavigation, Navigation, Scrollbar, Swiper, Pagination} from 'swiper';
Swiper.use([Navigation, HashNavigation, Scrollbar, Pagination]);

import { throttle } from 'throttle-debounce';

import SupportPopup from "./functions/support-popup";

global.Loader = require('@googlemaps/js-api-loader').Loader;

// enable top scroll functionality
$(window).scroll(throttle(200, false, function () {
    let $this = $(this);
    let scrollTop = $this.scrollTop();

    $('[data-top-scroll-active-class], [data-top-scroll-inactive-class]').each(function (index, e) {
        let $e = $(e);

        let threshold = toPx($e.attr('data-top-scroll-threshold')) || 32;

        let activeClass = $e.attr('data-top-scroll-active-class');
        let inactiveClass = $e.attr('data-top-scroll-inactive-class');

        if (scrollTop > threshold) {
            if (inactiveClass !== null) {
                $e.removeClass(inactiveClass);
            }

            if (activeClass !== null) {
                $e.addClass(activeClass);
            }
        } else {
            if (activeClass !== null) {
                $e.removeClass(activeClass);
            }

            if (inactiveClass !== null) {
                $e.addClass(inactiveClass);
            }
        }
    });
}));

// home testimonial swiper
const homeTestimonialSwiper = new Swiper('.home-testimonials__container__slider > .swiper-container', {
    navigation: {
        prevEl: '.home-testimonials__container__slider__navigation__previous',
        nextEl: '.home-testimonials__container__slider__navigation__next'
    },

    pagination: {
        el: '.home-testimonials__container__slider__pagination',
        type: 'bullets',
        clickable: true
    }
});

// enable desktop navigation overlay toggles
$('[data-site-navigation-overlay-desktop-toggle]').click(function () {
    const classOverlayActive = 'site-navigation-overlay-desktop--active';
    const classBodyActive = 'overflow-hidden';

    const $this = $(this);
    const $siteNavigationOverlayDesktop = $($this.attr('data-site-navigation-overlay-desktop-toggle'));
    const $body = $('body');

    if ($siteNavigationOverlayDesktop.length > 0) {
        if ($siteNavigationOverlayDesktop.hasClass(classOverlayActive)) {
            $siteNavigationOverlayDesktop.removeClass(classOverlayActive)
            $body.removeClass(classBodyActive);
        } else {
            $siteNavigationOverlayDesktop.addClass(classOverlayActive);
            $body.addClass(classBodyActive);
        }
    }
});

// wrap image frame around rich content images
$('.rich-content > img, .rich-content > p > img').each(function () {
    const $this = $(this);

    $this.wrap($('<div class="image-frame">').addClass($this.attr('class')));
});

// enable dynamic service content
function updateServiceContent(data)
{
    $('.js-service-title').text(data.title);
    $('.js-service-description').html(data.description);
    $('.js-service-detail-page-link').attr('href', data.detailPageUrl);
    $('.js-service-background-image').css('background-image', `url('${data.backgroundImageUrl}')`);
}

const $jsServiceItem = $('.js-service-item')

$jsServiceItem.click(function (e) {
    e.preventDefault();
    const $this = $(this);
    const data = $this.data();

    let isActive = $this.hasClass(data.activeClass);

    if (!isActive) {
        // remove active classes
        $('.js-service-item').each(function () {
            const $this = $(this);
            $this.removeClass($this.data().activeClass);
        });

        // add active class
        $this.addClass(data.activeClass);

        // update content
        updateServiceContent(data);
    }

    // Scroll to top
    $("html, body").animate({ scrollTop: 0 }, "slow");

});

if ($jsServiceItem.length > 0) {
    updateServiceContent($jsServiceItem.get()[0].dataset);
}

// support popup
window.supportPopup = new SupportPopup('#support-popup');

// enable navigation bar inertia
const $siteNavigationBarDesktop = $('.site-navigation-bar-desktop');
const classSitenavigationBarDesktopHidden = 'site-navigation-bar-desktop--hidden';

let lastPageY = window.pageYOffset;
let offsetY = 0;
let hidden = false;
let thresholdHidden = 140;

window.addEventListener('scroll', function () {
    const pageY = window.pageYOffset;
    const pageYDelta = pageY - lastPageY;
    lastPageY = pageY;

    if (hidden) {
        if (pageYDelta < 0) {
            $siteNavigationBarDesktop.removeClass(classSitenavigationBarDesktopHidden);
            offsetY = 0;
            hidden = false;

            $('[data-on-navigation-bar-hidden-class]').each(function () {
                const $this = $(this);
                $this.removeClass($this.attr('data-on-navigation-bar-hidden-class'));
            });
        }
    } else {
        offsetY = Math.min(Math.max(offsetY - pageYDelta / 3, -thresholdHidden), 0);

        if (offsetY === -thresholdHidden) {
            $siteNavigationBarDesktop.addClass(classSitenavigationBarDesktopHidden);
            hidden = true;

            $('[data-on-navigation-bar-hidden-class]').each(function () {
                const $this = $(this);
                $this.addClass($this.attr('data-on-navigation-bar-hidden-class'));
            });
        }
    }

    if (pageYDelta !== 0) {
        $siteNavigationBarDesktop.css('transform', `translate(0, ${offsetY}px)`);
    }
});

// DOC READY
$(function() {
    const scrollTracker = new ScrollTracker('js-scroll-tracker');
})

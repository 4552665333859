import Cookies from 'js-cookie/src/js.cookie';

export default class SupportPopup {

    constructor(selector = '#support-popup')
    {
        this.selector = selector;
        this.el = $(selector);
        this.maxShowCount = $(this.el).data('show-count') ?? 1;
        this.closeButton = $(this.el).find('.js-support-popup-close');

        this.init();
    }

    init()
    {
        if (!this.shouldDisplay()) {
            return;
        }
        this.assignClickListeners();
        this.displayPopup();
    }

    shouldDisplay()
    {
        return this.getCurrentShownCount() < this.maxShowCount;
    }

    displayPopup()
    {
        this.increaseShownCount();
        $(this.el).show();
    }

    hidePopup()
    {
        $(this.el).slideUp();
    }

    assignClickListeners()
    {
        // Listen for close button
        $(this.closeButton).on('click', () => {
            this.hidePopup();
        })
    }

    keepOnlyAlphaNumeric(str)
    {
        return str.replace(/[^a-z0-9]/gi,'');
    }

    getCookieName()
    {
         return 'popup-show-count-' + this.keepOnlyAlphaNumeric(this.selector);
    }

    getCurrentShownCount()
    {
        return Cookies.get(this.getCookieName()) ?? 0;
    }

    setShownCount(newCount)
    {
        Cookies.set(this.getCookieName(), newCount, { expires: 1 });
    }

    increaseShownCount()
    {
        let currentCount = this.getCurrentShownCount();
        currentCount++;
        this.setShownCount(currentCount);
    }
}
